import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import "../scss/mentions-legales.scss";

const MentionsLegales = () => {
	return (
		<Layout>
			<Seo title="Mentions légales" />
			<div className="page-container legal-notice">
				<h2 className="page-title">Mentions légales</h2>
				<section className="page-section">
					<p className="section-paragraph">
						Ce site internet a été développé par et pour l'association CLI sous licence{" "}
						<Link to="https://www.gnu.org/licenses/gpl-3.0.en.html">GPLv3</Link>. Il ne
						requiert le dépôt d'aucun cookie sur votre machine afin de fonctionner. Nous
						ne récoltons{" "}
						<Link to="https://www.cnil.fr/fr/maitriser-mes-donnees">activement</Link>{" "}
						aucune de vos données de naviguation.
					</p>
					<p className="section-paragraph">
						Réalisation l'illustration en page d'accueil & web design : Cécile Mosso.
					</p>
					<p className="section-paragraph">
						Réalisation du site & web design :{" "}
						<Link to="https://avalou.netlib.re">Lou Avazeri</Link>.
					</p>
					<p className="section-paragraph">
						L'image de fond de la page d'accueil a été générée grâce à{" "}
						<Link to="https://www.design.svgbackgrounds.com/">SVGBackgrounds.com</Link>
					</p>
					<p className="section-paragra">
						Les autres images libres de droit sont issues de{" "}
						<Link to="https://undraw.co/">undraw.co</Link>,{" "}
						<Link to="https://unsplash.com/">unsplash.com</Link> et{" "}
						<Link to="https://www.pexels.com/">pexels.com</Link>.
					</p>
					<p className="section-paragraph">
						Pour plus d'information concernant notre fonctionnement, consultez{" "}
						<Link to="/statuts">nos statuts</Link>.
					</p>
				</section>
			</div>
		</Layout>
	);
};

export default MentionsLegales;
